import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0a24b8a8 = () => interopDefault(import('../pages/address-book/index.vue' /* webpackChunkName: "pages/address-book/index" */))
const _eb13e7ee = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _b6bf1280 = () => interopDefault(import('../pages/company-panel/index.vue' /* webpackChunkName: "pages/company-panel/index" */))
const _557b466a = () => interopDefault(import('../pages/contractors/index.vue' /* webpackChunkName: "pages/contractors/index" */))
const _43409cbe = () => interopDefault(import('../pages/crews/index.vue' /* webpackChunkName: "pages/crews/index" */))
const _59c52cd1 = () => interopDefault(import('../pages/employees/index.vue' /* webpackChunkName: "pages/employees/index" */))
const _1c2cbf2f = () => interopDefault(import('../pages/equipments/index.vue' /* webpackChunkName: "pages/equipments/index" */))
const _6dd5a16c = () => interopDefault(import('../pages/hazard-register/index.vue' /* webpackChunkName: "pages/hazard-register/index" */))
const _28255fa6 = () => interopDefault(import('../pages/incidents/index.vue' /* webpackChunkName: "pages/incidents/index" */))
const _554aa732 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _29367ad4 = () => interopDefault(import('../pages/machines/index.vue' /* webpackChunkName: "pages/machines/index" */))
const _d9bb5ee6 = () => interopDefault(import('../pages/reminders/index.vue' /* webpackChunkName: "pages/reminders/index" */))
const _d5b99304 = () => interopDefault(import('../pages/safety-alerts/index.vue' /* webpackChunkName: "pages/safety-alerts/index" */))
const _09d388ed = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _5f5aeb18 = () => interopDefault(import('../pages/sites/index.vue' /* webpackChunkName: "pages/sites/index" */))
const _528d5ea2 = () => interopDefault(import('../pages/summary.vue' /* webpackChunkName: "pages/summary" */))
const _57ac57f2 = () => interopDefault(import('../pages/summary/index.vue' /* webpackChunkName: "pages/summary/index" */))
const _5e251b5c = () => interopDefault(import('../pages/summary/crews.vue' /* webpackChunkName: "pages/summary/crews" */))
const _6d8d3cfe = () => interopDefault(import('../pages/summary/incidents.vue' /* webpackChunkName: "pages/summary/incidents" */))
const _a10a2750 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _f7b58e08 = () => interopDefault(import('../pages/training-competency/index.vue' /* webpackChunkName: "pages/training-competency/index" */))
const _9eadb2de = () => interopDefault(import('../pages/vehicles/index.vue' /* webpackChunkName: "pages/vehicles/index" */))
const _6f1bdba7 = () => interopDefault(import('../pages/company-panel/view.vue' /* webpackChunkName: "pages/company-panel/view" */))
const _0cee6e2c = () => interopDefault(import('../pages/company-panel/view/index.vue' /* webpackChunkName: "pages/company-panel/view/index" */))
const _222f7e17 = () => interopDefault(import('../pages/company-panel/view/edit-details.vue' /* webpackChunkName: "pages/company-panel/view/edit-details" */))
const _1f4bb567 = () => interopDefault(import('../pages/company-panel/view/manage-access/index.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/index" */))
const _06700be2 = () => interopDefault(import('../pages/company-panel/view/manage-crews.vue' /* webpackChunkName: "pages/company-panel/view/manage-crews" */))
const _1347dab5 = () => interopDefault(import('../pages/company-panel/view/manage-employees.vue' /* webpackChunkName: "pages/company-panel/view/manage-employees" */))
const _5a4aac20 = () => interopDefault(import('../pages/company-panel/view/manage-access/view.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view" */))
const _5b2d9aa3 = () => interopDefault(import('../pages/company-panel/view/manage-access/view/index.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view/index" */))
const _2e1a1153 = () => interopDefault(import('../pages/company-panel/view/manage-access/view/groups.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view/groups" */))
const _f1ec0d84 = () => interopDefault(import('../pages/company/view.vue' /* webpackChunkName: "pages/company/view" */))
const _2f7a1941 = () => interopDefault(import('../pages/company/view/index.vue' /* webpackChunkName: "pages/company/view/index" */))
const _12325066 = () => interopDefault(import('../pages/company/view/files.vue' /* webpackChunkName: "pages/company/view/files" */))
const _45a31b7b = () => interopDefault(import('../pages/company/view/objectives.vue' /* webpackChunkName: "pages/company/view/objectives" */))
const _16475a5a = () => interopDefault(import('../pages/company/view/policy.vue' /* webpackChunkName: "pages/company/view/policy" */))
const _7f60045f = () => interopDefault(import('../pages/employees/new.vue' /* webpackChunkName: "pages/employees/new" */))
const _639a0684 = () => interopDefault(import('../pages/equipments/add.vue' /* webpackChunkName: "pages/equipments/add" */))
const _20b14ebb = () => interopDefault(import('../pages/incidents/new.vue' /* webpackChunkName: "pages/incidents/new" */))
const _3692ade5 = () => interopDefault(import('../pages/machines/add.vue' /* webpackChunkName: "pages/machines/add" */))
const _1fc2d568 = () => interopDefault(import('../pages/safety-alerts/new.vue' /* webpackChunkName: "pages/safety-alerts/new" */))
const _1eb1284d = () => interopDefault(import('../pages/sites/register.vue' /* webpackChunkName: "pages/sites/register" */))
const _e780fde0 = () => interopDefault(import('../pages/sites/register/index.vue' /* webpackChunkName: "pages/sites/register/index" */))
const _2eba5215 = () => interopDefault(import('../pages/sites/register/_forestid.vue' /* webpackChunkName: "pages/sites/register/_forestid" */))
const _e7f456c0 = () => interopDefault(import('../pages/vehicles/add.vue' /* webpackChunkName: "pages/vehicles/add" */))
const _64f7cd52 = () => interopDefault(import('../pages/reminders/goto/crewgear.vue' /* webpackChunkName: "pages/reminders/goto/crewgear" */))
const _26102bb8 = () => interopDefault(import('../pages/reminders/goto/employeegear.vue' /* webpackChunkName: "pages/reminders/goto/employeegear" */))
const _241fd8a0 = () => interopDefault(import('../pages/company-panel/manage-access/group/_groupuuid.vue' /* webpackChunkName: "pages/company-panel/manage-access/group/_groupuuid" */))
const _4d193746 = () => interopDefault(import('../pages/company-panel/manage-access/user/_useruuid.vue' /* webpackChunkName: "pages/company-panel/manage-access/user/_useruuid" */))
const _78f43f3b = () => interopDefault(import('../pages/company/program/_versionid/index.vue' /* webpackChunkName: "pages/company/program/_versionid/index" */))
const _4ab227ce = () => interopDefault(import('../pages/company/program/_versionid/policy.vue' /* webpackChunkName: "pages/company/program/_versionid/policy" */))
const _44ad1188 = () => interopDefault(import('../pages/employees/_id/index.vue' /* webpackChunkName: "pages/employees/_id/index" */))
const _0e188c98 = () => interopDefault(import('../pages/incidents/_id/index.vue' /* webpackChunkName: "pages/incidents/_id/index" */))
const _7b04584a = () => interopDefault(import('../pages/sites/_siteid/index.vue' /* webpackChunkName: "pages/sites/_siteid/index" */))
const _3c85bf96 = () => interopDefault(import('../pages/summarynew/_drillid.vue' /* webpackChunkName: "pages/summarynew/_drillid" */))
const _42a09e6f = () => interopDefault(import('../pages/contractors/_companyid/agreement/index.vue' /* webpackChunkName: "pages/contractors/_companyid/agreement/index" */))
const _2ff889c0 = () => interopDefault(import('../pages/contractors/_companyid/reviews/index.vue' /* webpackChunkName: "pages/contractors/_companyid/reviews/index" */))
const _28f60420 = () => interopDefault(import('../pages/crews/_crewid/documents.vue' /* webpackChunkName: "pages/crews/_crewid/documents" */))
const _6cb75073 = () => interopDefault(import('../pages/crews/_crewid/documents/index.vue' /* webpackChunkName: "pages/crews/_crewid/documents/index" */))
const _4f6f8798 = () => interopDefault(import('../pages/crews/_crewid/documents/files.vue' /* webpackChunkName: "pages/crews/_crewid/documents/files" */))
const _6e113f2e = () => interopDefault(import('../pages/crews/_crewid/documents/gear-tracker.vue' /* webpackChunkName: "pages/crews/_crewid/documents/gear-tracker" */))
const _ea56095e = () => interopDefault(import('../pages/crews/_crewid/documents/safe-start.vue' /* webpackChunkName: "pages/crews/_crewid/documents/safe-start" */))
const _6c8537b5 = () => interopDefault(import('../pages/crews/_crewid/documents/substance-inv.vue' /* webpackChunkName: "pages/crews/_crewid/documents/substance-inv" */))
const _6bc130c5 = () => interopDefault(import('../pages/crews/_crewid/monthly.vue' /* webpackChunkName: "pages/crews/_crewid/monthly" */))
const _69d560f0 = () => interopDefault(import('../pages/crews/_crewid/monthly/index.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/index" */))
const _58cd659c = () => interopDefault(import('../pages/crews/_crewid/monthly/audits.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/audits" */))
const _ba182024 = () => interopDefault(import('../pages/crews/_crewid/monthly/emergency-drills.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/emergency-drills" */))
const _30262ee9 = () => interopDefault(import('../pages/crews/_crewid/monthly/safety-meetings.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/safety-meetings" */))
const _aa76ba2e = () => interopDefault(import('../pages/crews/_crewid/monthly/safety-review.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/safety-review" */))
const _f6a486a6 = () => interopDefault(import('../pages/crews/_crewid/monthly/sbos.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/sbos" */))
const _1d851c0c = () => interopDefault(import('../pages/crews/_crewid/monthly/system-audits.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/system-audits" */))
const _4e67ffac = () => interopDefault(import('../pages/crews/_crewid/monthly/toolbox/index.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/toolbox/index" */))
const _5cce5ffa = () => interopDefault(import('../pages/crews/_crewid/monthly/toolbox/toolbox.unit.js' /* webpackChunkName: "pages/crews/_crewid/monthly/toolbox/toolbox.unit" */))
const _edae56e0 = () => interopDefault(import('../pages/employees/_id/edit.vue' /* webpackChunkName: "pages/employees/_id/edit" */))
const _70f2dbab = () => interopDefault(import('../pages/employees/_id/view.vue' /* webpackChunkName: "pages/employees/_id/view" */))
const _5c8e9424 = () => interopDefault(import('../pages/employees/_id/view/index.vue' /* webpackChunkName: "pages/employees/_id/view/index" */))
const _05c0dc2f = () => interopDefault(import('../pages/employees/_id/view/compliance.vue' /* webpackChunkName: "pages/employees/_id/view/compliance" */))
const _976b1816 = () => interopDefault(import('../pages/employees/_id/view/drug-alcohol.vue' /* webpackChunkName: "pages/employees/_id/view/drug-alcohol" */))
const _971e25da = () => interopDefault(import('../pages/employees/_id/view/files.vue' /* webpackChunkName: "pages/employees/_id/view/files" */))
const _6ec422cb = () => interopDefault(import('../pages/employees/_id/view/forms.vue' /* webpackChunkName: "pages/employees/_id/view/forms" */))
const _02a3badd = () => interopDefault(import('../pages/employees/_id/view/induction.vue' /* webpackChunkName: "pages/employees/_id/view/induction" */))
const _e8fcfda6 = () => interopDefault(import('../pages/employees/_id/view/linked.vue' /* webpackChunkName: "pages/employees/_id/view/linked" */))
const _bb4641a6 = () => interopDefault(import('../pages/employees/_id/view/medical.vue' /* webpackChunkName: "pages/employees/_id/view/medical" */))
const _1ea362ec = () => interopDefault(import('../pages/employees/_id/view/ppe-tracker.vue' /* webpackChunkName: "pages/employees/_id/view/ppe-tracker" */))
const _2ccb2d94 = () => interopDefault(import('../pages/employees/_id/view/record-learning.vue' /* webpackChunkName: "pages/employees/_id/view/record-learning" */))
const _8f53553a = () => interopDefault(import('../pages/employees/_id/view/training-records.vue' /* webpackChunkName: "pages/employees/_id/view/training-records" */))
const _0a514828 = () => interopDefault(import('../pages/equipments/_equipmentid/edit.vue' /* webpackChunkName: "pages/equipments/_equipmentid/edit" */))
const _f6f7daf6 = () => interopDefault(import('../pages/equipments/_equipmentid/files.vue' /* webpackChunkName: "pages/equipments/_equipmentid/files" */))
const _9c0332dc = () => interopDefault(import('../pages/equipments/_equipmentid/induction/index.vue' /* webpackChunkName: "pages/equipments/_equipmentid/induction/index" */))
const _01c67296 = () => interopDefault(import('../pages/equipments/_equipmentid/prepurchase/index.vue' /* webpackChunkName: "pages/equipments/_equipmentid/prepurchase/index" */))
const _13d8b898 = () => interopDefault(import('../pages/incidents/_id/edit.vue' /* webpackChunkName: "pages/incidents/_id/edit" */))
const _3a18b18c = () => interopDefault(import('../pages/machines/_machineid/edit.vue' /* webpackChunkName: "pages/machines/_machineid/edit" */))
const _c01d9e12 = () => interopDefault(import('../pages/machines/_machineid/files.vue' /* webpackChunkName: "pages/machines/_machineid/files" */))
const _43f59e84 = () => interopDefault(import('../pages/machines/_machineid/induction/index.vue' /* webpackChunkName: "pages/machines/_machineid/induction/index" */))
const _1fb46bb2 = () => interopDefault(import('../pages/machines/_machineid/prepurchase/index.vue' /* webpackChunkName: "pages/machines/_machineid/prepurchase/index" */))
const _c15ebe46 = () => interopDefault(import('../pages/sites/_siteid/view.vue' /* webpackChunkName: "pages/sites/_siteid/view" */))
const _f23364c0 = () => interopDefault(import('../pages/sites/_siteid/view/index.vue' /* webpackChunkName: "pages/sites/_siteid/view/index" */))
const _175b482a = () => interopDefault(import('../pages/sites/_siteid/view/covid-qr.vue' /* webpackChunkName: "pages/sites/_siteid/view/covid-qr" */))
const _7e9834be = () => interopDefault(import('../pages/sites/_siteid/view/emergency-information.vue' /* webpackChunkName: "pages/sites/_siteid/view/emergency-information" */))
const _699e84c5 = () => interopDefault(import('../pages/sites/_siteid/view/files.vue' /* webpackChunkName: "pages/sites/_siteid/view/files" */))
const _50e7d6f8 = () => interopDefault(import('../pages/sites/_siteid/view/hazard-id.vue' /* webpackChunkName: "pages/sites/_siteid/view/hazard-id" */))
const _a734a54e = () => interopDefault(import('../pages/sites/_siteid/view/more-forms.vue' /* webpackChunkName: "pages/sites/_siteid/view/more-forms" */))
const _4f7a22e7 = () => interopDefault(import('../pages/sites/_siteid/view/visitors.vue' /* webpackChunkName: "pages/sites/_siteid/view/visitors" */))
const _7ffe3de6 = () => interopDefault(import('../pages/sites/_siteid/visitor.vue' /* webpackChunkName: "pages/sites/_siteid/visitor" */))
const _ff7b802e = () => interopDefault(import('../pages/sites/_siteid/visitor/index.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/index" */))
const _f7d70998 = () => interopDefault(import('../pages/sites/_siteid/visitor/addressbook.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/addressbook" */))
const _0e480c6e = () => interopDefault(import('../pages/sites/_siteid/visitor/contractor.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/contractor" */))
const _12ec65ec = () => interopDefault(import('../pages/sites/_siteid/visitor/infrequent.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/infrequent" */))
const _15d7f170 = () => interopDefault(import('../pages/vehicles/_vehicleid/edit.vue' /* webpackChunkName: "pages/vehicles/_vehicleid/edit" */))
const _f9585de4 = () => interopDefault(import('../pages/contractors/_companyid/reviews/new.vue' /* webpackChunkName: "pages/contractors/_companyid/reviews/new" */))
const _6b7db081 = () => interopDefault(import('../pages/crews/_crewid/audits/new.vue' /* webpackChunkName: "pages/crews/_crewid/audits/new" */))
const _ddf97030 = () => interopDefault(import('../pages/crews/_crewid/emergency-drill/new.vue' /* webpackChunkName: "pages/crews/_crewid/emergency-drill/new" */))
const _6fddad96 = () => interopDefault(import('../pages/crews/_crewid/safe-start-stop/new.vue' /* webpackChunkName: "pages/crews/_crewid/safe-start-stop/new" */))
const _0a47fa6e = () => interopDefault(import('../pages/crews/_crewid/safety-meeting/new.vue' /* webpackChunkName: "pages/crews/_crewid/safety-meeting/new" */))
const _723459aa = () => interopDefault(import('../pages/crews/_crewid/safety-review/health-representative-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/health-representative-review" */))
const _72a02d7c = () => interopDefault(import('../pages/crews/_crewid/safety-review/new.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/new" */))
const _5b8243ab = () => interopDefault(import('../pages/crews/_crewid/safety-review/site-supervisor-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/site-supervisor-review" */))
const _7bae903a = () => interopDefault(import('../pages/crews/_crewid/safety-review/worker-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/worker-review" */))
const _d31ae0ce = () => interopDefault(import('../pages/crews/_crewid/safety-sheets/add.vue' /* webpackChunkName: "pages/crews/_crewid/safety-sheets/add" */))
const _22dff68a = () => interopDefault(import('../pages/crews/_crewid/systems-audit/new.vue' /* webpackChunkName: "pages/crews/_crewid/systems-audit/new" */))
const _2c742630 = () => interopDefault(import('../pages/employees/_id/forms/da-consent.vue' /* webpackChunkName: "pages/employees/_id/forms/da-consent" */))
const _5c87069b = () => interopDefault(import('../pages/employees/_id/forms/rol-request.vue' /* webpackChunkName: "pages/employees/_id/forms/rol-request" */))
const _19d910cf = () => interopDefault(import('../pages/employees/_id/medical-information/edit.vue' /* webpackChunkName: "pages/employees/_id/medical-information/edit" */))
const _c960b6f8 = () => interopDefault(import('../pages/employees/_id/record-learning/request.vue' /* webpackChunkName: "pages/employees/_id/record-learning/request" */))
const _3d6da27a = () => interopDefault(import('../pages/equipments/_equipmentid/induction/edit.vue' /* webpackChunkName: "pages/equipments/_equipmentid/induction/edit" */))
const _de46717a = () => interopDefault(import('../pages/equipments/_equipmentid/prepurchase/new.vue' /* webpackChunkName: "pages/equipments/_equipmentid/prepurchase/new" */))
const _1096a448 = () => interopDefault(import('../pages/machines/_machineid/induction/edit.vue' /* webpackChunkName: "pages/machines/_machineid/induction/edit" */))
const _22d3ff35 = () => interopDefault(import('../pages/machines/_machineid/prepurchase/new.vue' /* webpackChunkName: "pages/machines/_machineid/prepurchase/new" */))
const _11ae7601 = () => interopDefault(import('../pages/sites/_siteid/emergency-information/edit.vue' /* webpackChunkName: "pages/sites/_siteid/emergency-information/edit" */))
const _b9012966 = () => interopDefault(import('../pages/sites/_siteid/enviromentalchecklist/new.vue' /* webpackChunkName: "pages/sites/_siteid/enviromentalchecklist/new" */))
const _1f45b2fb = () => interopDefault(import('../pages/sites/_siteid/hazard-id/edit.vue' /* webpackChunkName: "pages/sites/_siteid/hazard-id/edit" */))
const _4f469573 = () => interopDefault(import('../pages/sites/_siteid/presite/new.vue' /* webpackChunkName: "pages/sites/_siteid/presite/new" */))
const _1885c16b = () => interopDefault(import('../pages/crews/_crewid/sbos/new/_formid.vue' /* webpackChunkName: "pages/crews/_crewid/sbos/new/_formid" */))
const _5bcaf532 = () => interopDefault(import('../pages/crews/_crewid/toolbox/new/_toolboxid.vue' /* webpackChunkName: "pages/crews/_crewid/toolbox/new/_toolboxid" */))
const _e7b03cda = () => interopDefault(import('../pages/crews/_crewid/sbos/view/_formid/_id.vue' /* webpackChunkName: "pages/crews/_crewid/sbos/view/_formid/_id" */))
const _61f30393 = () => interopDefault(import('../pages/crews/_crewid/toolbox/view/_formid/_id.vue' /* webpackChunkName: "pages/crews/_crewid/toolbox/view/_formid/_id" */))
const _6f9dfc28 = () => interopDefault(import('../pages/contractors/_companyid/reviews/_id.vue' /* webpackChunkName: "pages/contractors/_companyid/reviews/_id" */))
const _06eb24c4 = () => interopDefault(import('../pages/crews/_crewid/audits/_form.vue' /* webpackChunkName: "pages/crews/_crewid/audits/_form" */))
const _57c7db9b = () => interopDefault(import('../pages/crews/_crewid/audits/_id.vue' /* webpackChunkName: "pages/crews/_crewid/audits/_id" */))
const _47a9e57e = () => interopDefault(import('../pages/crews/_crewid/audits/_view_audit.vue' /* webpackChunkName: "pages/crews/_crewid/audits/_view_audit" */))
const _67345a42 = () => interopDefault(import('../pages/crews/_crewid/emergency-drill/_drillid.vue' /* webpackChunkName: "pages/crews/_crewid/emergency-drill/_drillid" */))
const _5c27d8b0 = () => interopDefault(import('../pages/crews/_crewid/safe-start-stop/_id.vue' /* webpackChunkName: "pages/crews/_crewid/safe-start-stop/_id" */))
const _1f11327e = () => interopDefault(import('../pages/crews/_crewid/safety-meeting/_meetingid.vue' /* webpackChunkName: "pages/crews/_crewid/safety-meeting/_meetingid" */))
const _4a4ba056 = () => interopDefault(import('../pages/crews/_crewid/systems-audit/_id.vue' /* webpackChunkName: "pages/crews/_crewid/systems-audit/_id" */))
const _e06cd332 = () => interopDefault(import('../pages/sites/_siteid/enviromentalchecklist/_id.vue' /* webpackChunkName: "pages/sites/_siteid/enviromentalchecklist/_id" */))
const _3b90c08d = () => interopDefault(import('../pages/sites/_siteid/presite/_id.vue' /* webpackChunkName: "pages/sites/_siteid/presite/_id" */))
const _48298bc2 = () => interopDefault(import('../pages/crews/_crewid/safety-review/_id/meeting.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/_id/meeting" */))
const _7caf862f = () => interopDefault(import('../pages/crews/_crewid/safety-review/_id/meeting.unit.js' /* webpackChunkName: "pages/crews/_crewid/safety-review/_id/meeting.unit" */))
const _4bb2ba60 = () => interopDefault(import('../pages/crews/_crewid/safety-review/_id/_type.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/_id/_type" */))
const _fd52fcc2 = () => interopDefault(import('../pages/contractors/_companyid/_id/index.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/index" */))
const _138c14b0 = () => interopDefault(import('../pages/sop/_id/_version.vue' /* webpackChunkName: "pages/sop/_id/_version" */))
const _1bcd0e30 = () => interopDefault(import('../pages/contractors/_companyid/_id/view.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view" */))
const _2b91b16b = () => interopDefault(import('../pages/contractors/_companyid/_id/view/index.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/index" */))
const _73f496f3 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/file.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/file" */))
const _6ec5c370 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/forms.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/forms" */))
const _ef4e26ee = () => interopDefault(import('../pages/contractors/_companyid/_id/view/inductions.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/inductions" */))
const _2eb917ee = () => interopDefault(import('../pages/contractors/_companyid/_id/worker-information/edit.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/worker-information/edit" */))
const _e8b9f7ca = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/address-book",
    component: _0a24b8a8,
    name: "address-book"
  }, {
    path: "/company",
    component: _eb13e7ee,
    name: "company"
  }, {
    path: "/company-panel",
    component: _b6bf1280,
    name: "company-panel"
  }, {
    path: "/contractors",
    component: _557b466a,
    name: "contractors"
  }, {
    path: "/crews",
    component: _43409cbe,
    name: "crews"
  }, {
    path: "/employees",
    component: _59c52cd1,
    name: "employees"
  }, {
    path: "/equipments",
    component: _1c2cbf2f,
    name: "equipments"
  }, {
    path: "/hazard-register",
    component: _6dd5a16c,
    name: "hazard-register"
  }, {
    path: "/incidents",
    component: _28255fa6,
    name: "incidents"
  }, {
    path: "/login",
    component: _554aa732,
    name: "login"
  }, {
    path: "/machines",
    component: _29367ad4,
    name: "machines"
  }, {
    path: "/reminders",
    component: _d9bb5ee6,
    name: "reminders"
  }, {
    path: "/safety-alerts",
    component: _d5b99304,
    name: "safety-alerts"
  }, {
    path: "/settings",
    component: _09d388ed,
    name: "settings"
  }, {
    path: "/sites",
    component: _5f5aeb18,
    name: "sites"
  }, {
    path: "/summary",
    component: _528d5ea2,
    children: [{
      path: "",
      component: _57ac57f2,
      name: "summary"
    }, {
      path: "crews",
      component: _5e251b5c,
      name: "summary-crews"
    }, {
      path: "incidents",
      component: _6d8d3cfe,
      name: "summary-incidents"
    }]
  }, {
    path: "/support",
    component: _a10a2750,
    name: "support"
  }, {
    path: "/training-competency",
    component: _f7b58e08,
    name: "training-competency"
  }, {
    path: "/vehicles",
    component: _9eadb2de,
    name: "vehicles"
  }, {
    path: "/company-panel/view",
    component: _6f1bdba7,
    children: [{
      path: "",
      component: _0cee6e2c,
      name: "company-panel-view"
    }, {
      path: "edit-details",
      component: _222f7e17,
      name: "company-panel-view-edit-details"
    }, {
      path: "manage-access",
      component: _1f4bb567,
      name: "company-panel-view-manage-access"
    }, {
      path: "manage-crews",
      component: _06700be2,
      name: "company-panel-view-manage-crews"
    }, {
      path: "manage-employees",
      component: _1347dab5,
      name: "company-panel-view-manage-employees"
    }, {
      path: "manage-access/view",
      component: _5a4aac20,
      children: [{
        path: "",
        component: _5b2d9aa3,
        name: "company-panel-view-manage-access-view"
      }, {
        path: "groups",
        component: _2e1a1153,
        name: "company-panel-view-manage-access-view-groups"
      }]
    }]
  }, {
    path: "/company/view",
    component: _f1ec0d84,
    children: [{
      path: "",
      component: _2f7a1941,
      name: "company-view"
    }, {
      path: "files",
      component: _12325066,
      name: "company-view-files"
    }, {
      path: "objectives",
      component: _45a31b7b,
      name: "company-view-objectives"
    }, {
      path: "policy",
      component: _16475a5a,
      name: "company-view-policy"
    }]
  }, {
    path: "/employees/new",
    component: _7f60045f,
    name: "employees-new"
  }, {
    path: "/equipments/add",
    component: _639a0684,
    name: "equipments-add"
  }, {
    path: "/incidents/new",
    component: _20b14ebb,
    name: "incidents-new"
  }, {
    path: "/machines/add",
    component: _3692ade5,
    name: "machines-add"
  }, {
    path: "/safety-alerts/new",
    component: _1fc2d568,
    name: "safety-alerts-new"
  }, {
    path: "/sites/register",
    component: _1eb1284d,
    children: [{
      path: "",
      component: _e780fde0,
      name: "sites-register"
    }, {
      path: ":forestid",
      component: _2eba5215,
      name: "sites-register-forestid"
    }]
  }, {
    path: "/vehicles/add",
    component: _e7f456c0,
    name: "vehicles-add"
  }, {
    path: "/reminders/goto/crewgear",
    component: _64f7cd52,
    name: "reminders-goto-crewgear"
  }, {
    path: "/reminders/goto/employeegear",
    component: _26102bb8,
    name: "reminders-goto-employeegear"
  }, {
    path: "/company-panel/manage-access/group/:groupuuid?",
    component: _241fd8a0,
    name: "company-panel-manage-access-group-groupuuid"
  }, {
    path: "/company-panel/manage-access/user/:useruuid?",
    component: _4d193746,
    name: "company-panel-manage-access-user-useruuid"
  }, {
    path: "/company/program/:versionid",
    component: _78f43f3b,
    name: "company-program-versionid"
  }, {
    path: "/company/program/:versionid?/policy",
    component: _4ab227ce,
    name: "company-program-versionid-policy"
  }, {
    path: "/employees/:id",
    component: _44ad1188,
    name: "employees-id"
  }, {
    path: "/incidents/:id",
    component: _0e188c98,
    name: "incidents-id"
  }, {
    path: "/sites/:siteid",
    component: _7b04584a,
    name: "sites-siteid"
  }, {
    path: "/summarynew/:drillid?",
    component: _3c85bf96,
    name: "summarynew-drillid"
  }, {
    path: "/contractors/:companyid/agreement",
    component: _42a09e6f,
    name: "contractors-companyid-agreement"
  }, {
    path: "/contractors/:companyid/reviews",
    component: _2ff889c0,
    name: "contractors-companyid-reviews"
  }, {
    path: "/crews/:crewid/documents",
    component: _28f60420,
    children: [{
      path: "",
      component: _6cb75073,
      name: "crews-crewid-documents"
    }, {
      path: "files",
      component: _4f6f8798,
      name: "crews-crewid-documents-files"
    }, {
      path: "gear-tracker",
      component: _6e113f2e,
      name: "crews-crewid-documents-gear-tracker"
    }, {
      path: "safe-start",
      component: _ea56095e,
      name: "crews-crewid-documents-safe-start"
    }, {
      path: "substance-inv",
      component: _6c8537b5,
      name: "crews-crewid-documents-substance-inv"
    }]
  }, {
    path: "/crews/:crewid/monthly",
    component: _6bc130c5,
    children: [{
      path: "",
      component: _69d560f0,
      name: "crews-crewid-monthly"
    }, {
      path: "audits",
      component: _58cd659c,
      name: "crews-crewid-monthly-audits"
    }, {
      path: "emergency-drills",
      component: _ba182024,
      name: "crews-crewid-monthly-emergency-drills"
    }, {
      path: "safety-meetings",
      component: _30262ee9,
      name: "crews-crewid-monthly-safety-meetings"
    }, {
      path: "safety-review",
      component: _aa76ba2e,
      name: "crews-crewid-monthly-safety-review"
    }, {
      path: "sbos",
      component: _f6a486a6,
      name: "crews-crewid-monthly-sbos"
    }, {
      path: "system-audits",
      component: _1d851c0c,
      name: "crews-crewid-monthly-system-audits"
    }, {
      path: "toolbox",
      component: _4e67ffac,
      name: "crews-crewid-monthly-toolbox"
    }, {
      path: "toolbox/toolbox.unit",
      component: _5cce5ffa,
      name: "crews-crewid-monthly-toolbox-toolbox.unit"
    }]
  }, {
    path: "/employees/:id/edit",
    component: _edae56e0,
    name: "employees-id-edit"
  }, {
    path: "/employees/:id/view",
    component: _70f2dbab,
    children: [{
      path: "",
      component: _5c8e9424,
      name: "employees-id-view"
    }, {
      path: "compliance",
      component: _05c0dc2f,
      name: "employees-id-view-compliance"
    }, {
      path: "drug-alcohol",
      component: _976b1816,
      name: "employees-id-view-drug-alcohol"
    }, {
      path: "files",
      component: _971e25da,
      name: "employees-id-view-files"
    }, {
      path: "forms",
      component: _6ec422cb,
      name: "employees-id-view-forms"
    }, {
      path: "induction",
      component: _02a3badd,
      name: "employees-id-view-induction"
    }, {
      path: "linked",
      component: _e8fcfda6,
      name: "employees-id-view-linked"
    }, {
      path: "medical",
      component: _bb4641a6,
      name: "employees-id-view-medical"
    }, {
      path: "ppe-tracker",
      component: _1ea362ec,
      name: "employees-id-view-ppe-tracker"
    }, {
      path: "record-learning",
      component: _2ccb2d94,
      name: "employees-id-view-record-learning"
    }, {
      path: "training-records",
      component: _8f53553a,
      name: "employees-id-view-training-records"
    }]
  }, {
    path: "/equipments/:equipmentid/edit",
    component: _0a514828,
    name: "equipments-equipmentid-edit"
  }, {
    path: "/equipments/:equipmentid/files",
    component: _f6f7daf6,
    name: "equipments-equipmentid-files"
  }, {
    path: "/equipments/:equipmentid/induction",
    component: _9c0332dc,
    name: "equipments-equipmentid-induction"
  }, {
    path: "/equipments/:equipmentid/prepurchase",
    component: _01c67296,
    name: "equipments-equipmentid-prepurchase"
  }, {
    path: "/incidents/:id/edit",
    component: _13d8b898,
    name: "incidents-id-edit"
  }, {
    path: "/machines/:machineid/edit",
    component: _3a18b18c,
    name: "machines-machineid-edit"
  }, {
    path: "/machines/:machineid/files",
    component: _c01d9e12,
    name: "machines-machineid-files"
  }, {
    path: "/machines/:machineid/induction",
    component: _43f59e84,
    name: "machines-machineid-induction"
  }, {
    path: "/machines/:machineid/prepurchase",
    component: _1fb46bb2,
    name: "machines-machineid-prepurchase"
  }, {
    path: "/sites/:siteid/view",
    component: _c15ebe46,
    children: [{
      path: "",
      component: _f23364c0,
      name: "sites-siteid-view"
    }, {
      path: "covid-qr",
      component: _175b482a,
      name: "sites-siteid-view-covid-qr"
    }, {
      path: "emergency-information",
      component: _7e9834be,
      name: "sites-siteid-view-emergency-information"
    }, {
      path: "files",
      component: _699e84c5,
      name: "sites-siteid-view-files"
    }, {
      path: "hazard-id",
      component: _50e7d6f8,
      name: "sites-siteid-view-hazard-id"
    }, {
      path: "more-forms",
      component: _a734a54e,
      name: "sites-siteid-view-more-forms"
    }, {
      path: "visitors",
      component: _4f7a22e7,
      name: "sites-siteid-view-visitors"
    }]
  }, {
    path: "/sites/:siteid/visitor",
    component: _7ffe3de6,
    children: [{
      path: "",
      component: _ff7b802e,
      name: "sites-siteid-visitor"
    }, {
      path: "addressbook",
      component: _f7d70998,
      name: "sites-siteid-visitor-addressbook"
    }, {
      path: "contractor",
      component: _0e480c6e,
      name: "sites-siteid-visitor-contractor"
    }, {
      path: "infrequent",
      component: _12ec65ec,
      name: "sites-siteid-visitor-infrequent"
    }]
  }, {
    path: "/vehicles/:vehicleid/edit",
    component: _15d7f170,
    name: "vehicles-vehicleid-edit"
  }, {
    path: "/contractors/:companyid/reviews/new",
    component: _f9585de4,
    name: "contractors-companyid-reviews-new"
  }, {
    path: "/crews/:crewid/audits/new",
    component: _6b7db081,
    name: "crews-crewid-audits-new"
  }, {
    path: "/crews/:crewid/emergency-drill/new",
    component: _ddf97030,
    name: "crews-crewid-emergency-drill-new"
  }, {
    path: "/crews/:crewid/safe-start-stop/new",
    component: _6fddad96,
    name: "crews-crewid-safe-start-stop-new"
  }, {
    path: "/crews/:crewid/safety-meeting/new",
    component: _0a47fa6e,
    name: "crews-crewid-safety-meeting-new"
  }, {
    path: "/crews/:crewid/safety-review/health-representative-review",
    component: _723459aa,
    name: "crews-crewid-safety-review-health-representative-review"
  }, {
    path: "/crews/:crewid/safety-review/new",
    component: _72a02d7c,
    name: "crews-crewid-safety-review-new"
  }, {
    path: "/crews/:crewid/safety-review/site-supervisor-review",
    component: _5b8243ab,
    name: "crews-crewid-safety-review-site-supervisor-review"
  }, {
    path: "/crews/:crewid/safety-review/worker-review",
    component: _7bae903a,
    name: "crews-crewid-safety-review-worker-review"
  }, {
    path: "/crews/:crewid/safety-sheets/add",
    component: _d31ae0ce,
    name: "crews-crewid-safety-sheets-add"
  }, {
    path: "/crews/:crewid/systems-audit/new",
    component: _22dff68a,
    name: "crews-crewid-systems-audit-new"
  }, {
    path: "/employees/:id/forms/da-consent",
    component: _2c742630,
    name: "employees-id-forms-da-consent"
  }, {
    path: "/employees/:id/forms/rol-request",
    component: _5c87069b,
    name: "employees-id-forms-rol-request"
  }, {
    path: "/employees/:id/medical-information/edit",
    component: _19d910cf,
    name: "employees-id-medical-information-edit"
  }, {
    path: "/employees/:id/record-learning/request",
    component: _c960b6f8,
    name: "employees-id-record-learning-request"
  }, {
    path: "/equipments/:equipmentid/induction/edit",
    component: _3d6da27a,
    name: "equipments-equipmentid-induction-edit"
  }, {
    path: "/equipments/:equipmentid/prepurchase/new",
    component: _de46717a,
    name: "equipments-equipmentid-prepurchase-new"
  }, {
    path: "/machines/:machineid/induction/edit",
    component: _1096a448,
    name: "machines-machineid-induction-edit"
  }, {
    path: "/machines/:machineid/prepurchase/new",
    component: _22d3ff35,
    name: "machines-machineid-prepurchase-new"
  }, {
    path: "/sites/:siteid/emergency-information/edit",
    component: _11ae7601,
    name: "sites-siteid-emergency-information-edit"
  }, {
    path: "/sites/:siteid/enviromentalchecklist/new",
    component: _b9012966,
    name: "sites-siteid-enviromentalchecklist-new"
  }, {
    path: "/sites/:siteid/hazard-id/edit",
    component: _1f45b2fb,
    name: "sites-siteid-hazard-id-edit"
  }, {
    path: "/sites/:siteid/presite/new",
    component: _4f469573,
    name: "sites-siteid-presite-new"
  }, {
    path: "/crews/:crewid/sbos/new/:formid?",
    component: _1885c16b,
    name: "crews-crewid-sbos-new-formid"
  }, {
    path: "/crews/:crewid/toolbox/new/:toolboxid?",
    component: _5bcaf532,
    name: "crews-crewid-toolbox-new-toolboxid"
  }, {
    path: "/crews/:crewid/sbos/view/:formid?/:id?",
    component: _e7b03cda,
    name: "crews-crewid-sbos-view-formid-id"
  }, {
    path: "/crews/:crewid/toolbox/view/:formid?/:id?",
    component: _61f30393,
    name: "crews-crewid-toolbox-view-formid-id"
  }, {
    path: "/contractors/:companyid/reviews/:id",
    component: _6f9dfc28,
    name: "contractors-companyid-reviews-id"
  }, {
    path: "/crews/:crewid/audits/:form?",
    component: _06eb24c4,
    name: "crews-crewid-audits-form"
  }, {
    path: "/crews/:crewid/audits/:id?",
    component: _57c7db9b,
    name: "crews-crewid-audits-id"
  }, {
    path: "/crews/:crewid/audits/:view_audit?",
    component: _47a9e57e,
    name: "crews-crewid-audits-view_audit"
  }, {
    path: "/crews/:crewid/emergency-drill/:drillid?",
    component: _67345a42,
    name: "crews-crewid-emergency-drill-drillid"
  }, {
    path: "/crews/:crewid/safe-start-stop/:id?",
    component: _5c27d8b0,
    name: "crews-crewid-safe-start-stop-id"
  }, {
    path: "/crews/:crewid/safety-meeting/:meetingid?",
    component: _1f11327e,
    name: "crews-crewid-safety-meeting-meetingid"
  }, {
    path: "/crews/:crewid/systems-audit/:id?",
    component: _4a4ba056,
    name: "crews-crewid-systems-audit-id"
  }, {
    path: "/sites/:siteid/enviromentalchecklist/:id?",
    component: _e06cd332,
    name: "sites-siteid-enviromentalchecklist-id"
  }, {
    path: "/sites/:siteid/presite/:id?",
    component: _3b90c08d,
    name: "sites-siteid-presite-id"
  }, {
    path: "/crews/:crewid/safety-review/:id?/meeting",
    component: _48298bc2,
    name: "crews-crewid-safety-review-id-meeting"
  }, {
    path: "/crews/:crewid/safety-review/:id?/meeting.unit",
    component: _7caf862f,
    name: "crews-crewid-safety-review-id-meeting.unit"
  }, {
    path: "/crews/:crewid/safety-review/:id?/:type?",
    component: _4bb2ba60,
    name: "crews-crewid-safety-review-id-type"
  }, {
    path: "/contractors/:companyid/:id",
    component: _fd52fcc2,
    name: "contractors-companyid-id"
  }, {
    path: "/sop/:id?/:version?",
    component: _138c14b0,
    name: "sop-id-version"
  }, {
    path: "/contractors/:companyid/:id?/view",
    component: _1bcd0e30,
    children: [{
      path: "",
      component: _2b91b16b,
      name: "contractors-companyid-id-view"
    }, {
      path: "file",
      component: _73f496f3,
      name: "contractors-companyid-id-view-file"
    }, {
      path: "forms",
      component: _6ec5c370,
      name: "contractors-companyid-id-view-forms"
    }, {
      path: "inductions",
      component: _ef4e26ee,
      name: "contractors-companyid-id-view-inductions"
    }]
  }, {
    path: "/contractors/:companyid/:id?/worker-information/edit",
    component: _2eb917ee,
    name: "contractors-companyid-id-worker-information-edit"
  }, {
    path: "/",
    component: _e8b9f7ca,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
