export default {
  name: 'equipmentPrePurchase',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction.methodRequest(
      `/register/equipment/prepurchase/${data.equipmentid}`,
      'PUT',
      data
    )
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data
    ctx.router.push({
      name: `equipments-equipmentid-prepurchase-new`,
      params: { equipmentid: data.equipmentid },
      query: { sid: ctx.saveId },
    })
  },
}
