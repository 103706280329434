export default [
  {
    areaUUID: '191be820-f081-11ea-9cb8-cdf66828905b',
    name: 'Work Teams',
    link: 'crews',
    icon: 'account-group',
    category: 'Dashboards',
    api: 'crews/getCrews',
  },
  {
    areaUUID: '1c178610-f081-11ea-9cb8-cdf66828905b',
    name: 'Sites',
    link: 'sites',
    icon: 'office-building-marker',
    category: 'Dashboards',
  },
  {
    areaUUID: '1da80586-f081-11ea-9cb8-cdf66828905b',
    name: 'Employees',
    link: 'employees',
    icon: 'account',
    category: 'Dashboards',
    api: 'data/employees/getEmployees',
  },
  {
    areaUUID: '22be3a86-f081-11ea-9cb8-cdf66828905b',
    name: 'Company',
    link: 'company',
    icon: 'domain',
    category: 'Dashboards',
  },
  {
    areaUUID: '25d2d4a2-f081-11ea-9cb8-cdf66828905b',
    name: 'Contractors',
    link: 'contractors',
    icon: 'contacts',
    category: 'Dashboards',
    api: 'data/contractors/getContractors',
  },
  {
    areaUUID: '191be820-f081-11ea-9cb8-cdf66828905b',
    name: 'Audits',
    link: 'crews-crewid-monthly-audits',
    icon: 'clipboard',
    category: 'Dashboards',
  },
  {
    areaUUID: '282935de-f081-11ea-9cb8-cdf66828905b',
    name: 'Incidents',
    link: 'incidents',
    icon: 'clipboard-check-outline',
    category: 'Registers',
  },
  {
    areaUUID: '2dd4d0a6-f081-11ea-9cb8-cdf66828905b',
    name: 'Training and Competency',
    link: 'training-competency',
    icon: 'grid',
    category: 'Registers',
  },
  {
    areaUUID: '306fffb6-f081-11ea-9cb8-cdf66828905b',
    name: 'Summary',
    link: 'summary',
    icon: 'chart-line',
    category: 'Registers',
  },
  {
    areaUUID: '32be2018-f081-11ea-9cb8-cdf66828905b',
    name: 'Reminders',
    link: 'reminders',
    icon: 'alarm',
    category: 'Registers',
  },
  {
    areaUUID: '34e71444-f081-11ea-9cb8-cdf66828905b',
    name: 'Vehicles',
    link: 'vehicles',
    icon: 'car-pickup',
    category: 'Resources',
  },
  {
    areaUUID: '36533682-f081-11ea-9cb8-cdf66828905b',
    name: 'Machines',
    link: 'machines',
    icon: 'engine',
    category: 'Resources',
  },
  {
    areaUUID: '5814ad32-d9db-11ef-9e27-d9dda4c43219',
    name: 'Equipments',
    link: 'equipments',
    icon: 'tools',
    category: 'Resources',
  },
  {
    areaUUID: '389ea4f8-f081-11ea-9cb8-cdf66828905b',
    name: 'Address Book',
    link: 'address-book',
    icon: 'contacts',
    category: 'Resources',
    api: 'data/addressBook/getAddressBook',
  },
  {
    areaUUID: '3d290090-f081-11ea-9cb8-cdf66828905b',
    name: 'Hazard Register',
    link: 'hazard-register',
    icon: 'book-open-variant',
    category: 'Resources',
    api: 'data/hazardRegister/getRegister',
  },
  {
    areaUUID: '3f684050-f081-11ea-9cb8-cdf66828905b',
    name: 'Safety Alerts',
    link: 'safety-alerts',
    icon: 'shield-alert-outline',
    category: 'Resources',
  },
]
