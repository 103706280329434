export default {
  name: 'equipmentInduction',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction.methodRequest(
      `/register/equipment/induction/${data.equipmentid}`,
      'POST',
      data.questions
    )
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data
    ctx.router.push({
      name: `equipments-equipmentid-induction-edit`,
      params: { equipmentid: data.equipmentid },
      query: { sid: ctx.saveId },
    })
  },
}
