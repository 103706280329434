export default async ({ store, route, app }, inject) => {
  if (process.browser) {
    if ('serviceWorker' in navigator) {
      const workbox = await window.$workbox

      if (workbox) {
        const waitingEvent = (e) => {
          console.info('waiting', e)
          workbox.addEventListener('controlling', (event) => {
            console.info('controlling', event)
            // Reload the page after the new service worker takes control
            window.location.reload()
          })
          store.commit('app/appUpdateAvailable', true) // Notify the app that an update is available

          // Post message to skip the waiting phase and activate the new service worker immediately
          navigator.serviceWorker.getRegistration().then((registration) => {
            if (registration?.waiting) {
              registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            }
          })
        }

        workbox.addEventListener('waiting', waitingEvent)
        workbox.addEventListener('externalwaiting', waitingEvent)

        workbox.addEventListener('activated', () => {
          if (navigator.serviceWorker.controller) {
            window.location.reload()
          }
        })

        inject('updateApp', () => {
          navigator.serviceWorker.ready.then((registration) => {
            if (registration.waiting === null) {
              return window.location.reload()
            }
            return registration.waiting.postMessage({ type: 'SKIP_WAITING' })
          })
        })
      }
    }
  }
}
