export default {
  name: 'equipmentInductedMember',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction.methodRequest(
      `/register/equipment/induction/${data.equipmentid}/operator`,
      'POST',
      data
    )
  },
}
