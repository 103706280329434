export default {
  name: 'monthlyCrewAudit',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    console.log('Form Data --> ', data)
    data.questions = data.signageQuestions.concat(data.siteQuestions)
    return requestFunction
      .methodRequest(`/forms/view/${data.formId}`, 'PUT', data)
      // .methodRequest(`/audits`, 'PUT', data)
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `crews-crewid-audits-form`, params: { crewid: data.crewId, id: data.formId  }, query: { sid: ctx.saveId } }) 
    // ctx.router.push({ name: `crews-crewid-audits-new`, params: { crewid: data.crewid }, query: { sid: ctx.saveId } })
  }
}
